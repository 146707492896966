<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <!-- Cabezera de la pagina principal -->
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Cierres Campos</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Hidrocarburos</li>
                  <li class="breadcrumb-item active">Operación</li>
                  <li class="breadcrumb-item active">Procesos</li>
                  <li class="breadcrumb-item active">Cierres de Campos</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <!-- Cuerpo de la pagina -->
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <!-- Cabecera de la tarjeta -->
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <!-- Div que empuja el boton a la izquierda -->
                  <div class="col-md-10"></div>
                  <div class="col-md-2">
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn btn-success"
                        data-toggle="modal"
                        data-target="#modal-form-totalCierre-export"
                        @click="limpiarModal()"
                      >
                        <i class="far fa-file-excel"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary"
                        data-toggle="modal"
                        data-target="#modal-form-cierres-campos"
                        @click="abrirModal('Crear', null)"
                        v-show="
                          $store.getters.can(
                            'hidrocarburos.totalCierreCampos.create'
                          )
                        "
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.cabecera de la tarjeta -->
              <div class="card-body p-0">
                <table
                  class="
                    table table-bordered table-striped table-hover table-sm
                  "
                >
                  <thead>
                    <tr>
                      <th>
                        Sitio Descargue
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.sitio_id"
                          @input="getIndex()"
                        />
                      </th>
                      <th>
                        Producto Transportado
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.producto_liquido_id"
                          @input="getIndex()"
                        />
                      </th>
                      <th>
                        Ventana Enturne
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.ventana"
                          @input="getIndex()"
                        />
                      </th>
                      <th>
                        Fecha Descargue
                        <input
                          type="date"
                          class="form-control form-control-sm"
                          v-model="filtros.fecha"
                          @input="getIndex()"
                        />
                      </th>
                      <th>
                        Cantidad Producto (NSV)
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="filtros.cant_prod"
                          @input="getIndex()"
                        />
                      </th>
                      <th>
                        Cantidad Guias
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="filtros.cant_guias"
                          @input="getIndex()"
                        />
                      </th>
                      <th>
                        Estado
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.estado"
                          @change="getIndex()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="estado in listasForms.listaEstado"
                            :key="estado.numeracion"
                            :value="estado.numeracion"
                          >
                            {{ estado.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th style="text-align-last: center">Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in cierresCampos.data" :key="item.id">
                      <td class="text-center">{{ item.sitio.nombre }}</td>
                      <td class="text-center">
                        {{ item.producto_liquido.nombre }} ({{ item.producto_liquido.sitio.nombre }})
                      </td>
                      <td class="text-center">
                        {{
                          item.ventana_enturne
                            ? item.ventana_enturne.nombre
                            : ""
                        }}
                      </td>
                      <td class="text-center">{{ item.fecha }}</td>
                      <td class="text-center">{{ item.cant_prod }}</td>
                      <td class="text-center">{{ item.cant_guias }}</td>
                      <td class="text-center" v-if="item.estado !== 2">
                        <span class="badge badge-danger"> Pendiente </span>
                      </td>
                      <td class="text-center" v-else>
                        <span class="badge badge-success"> Completado </span>
                      </td>
                      <td class="text-right" style="width: 25px">
                        <div class="btn-group float-right">
                          <button
                            type="button"
                            class="btn btn-sm bg-navy"
                            data-toggle="modal"
                            data-target="#modal-diferencia-show"
                            v-show="
                              $store.getters.can(
                                'hidrocarburos.totalCierreCampos.edit'
                              )
                            "
                            @click="abrirModalDiferencia(item.id)"
                          >
                            <i class="fas fa-eye"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-sm bg-navy"
                            data-toggle="modal"
                            data-target="#modal-form-cierres-campos"
                            v-show="
                              $store.getters.can(
                                'hidrocarburos.totalCierreCampos.edit'
                              )
                            "
                            v-if="item.estado !== 2"
                            @click="abrirModal('Editar', item.id)"
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-sm btn-danger"
                            v-show="
                              $store.getters.can(
                                'hidrocarburos.totalCierreCampos.delete'
                              )
                            "
                            @click="destroy(item.id)"
                            v-if="item.estado !== 2"
                          >
                            <i class="fas fa-trash-alt"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="cierresCampos.total">
                  <p>
                    Mostrando del <b>{{ cierresCampos.from }}</b> al
                    <b>{{ cierresCampos.to }}</b> de un total:
                    <b>{{ cierresCampos.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="cierresCampos"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  align="right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
      </div>
      <!-- Modal de agregar y editar -->
      <div
        class="modal fade"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
        id="modal-form-cierres-campos"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header bg-frontera-top-left pt-2 pb-2">
              <h4 class="modal-title text-white">{{ modal.title }}</h4>
              <button
                type="button"
                class="close text-white"
                data-dismiss="modal"
                aria-label="Close"
                id="close-modal"
                ref="closeModal2"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <!-- Cuerpo del modal -->
            <div class="modal-body">
              <div class="row">
                <div class="form-group col-md-6">
                  <label>Sitio Descargue</label>
                  <v-select
                    :class="[
                      $v.form.sitio_id.$invalid ? 'is-invalid' : 'is-valid',
                      $store.getters.getDarkMode ? 'dark-vselect' : '',
                    ]"
                    v-model="sitio"
                    placeholder="Sitios"
                    label="nombre"
                    :options="listasForms.sitios"
                    class="form-control form-control-sm p-0"
                    @input="getSelectSitios()"
                  ></v-select>
                </div>
                <div
                  class="form-group col-md-6"
                  v-show="Object.keys(sitio).length > 0"
                >
                  <label>Ventana descargue</label>
                  <v-select
                    :class="[
                      $v.form.ventana_enturne_descargue_id.$invalid
                        ? 'is-invalid'
                        : 'is-valid',
                      $store.getters.getDarkMode ? 'dark-vselect' : '',
                    ]"
                    v-model="ventana_descargue"
                    placeholder="Ventanas"
                    label="nombre"
                    :options="listasForms.ventanas"
                    class="form-control form-control-sm p-0"
                    @input="getSelectVentana()"
                  ></v-select>
                </div>
                <div class="form-group col-md-6">
                  <label>Producto Transportado</label>
                  <v-select
                    :class="[
                      $v.form.producto_liquido_id.$invalid
                        ? 'is-invalid'
                        : 'is-valid',
                      $store.getters.getDarkMode ? 'dark-vselect' : '',
                    ]"
                    v-model="productoLiquido"
                    placeholder="Productos"
                    label="nombre"
                    :options="listasForms.productosLiquidos"
                    class="form-control form-control-sm p-0"
                    @input="getSelectProductos()"
                  ></v-select>
                </div>
                <div class="form-group col-md-3">
                  <label>Fecha Descargue</label>
                  <input
                    type="date"
                    class="form-control form-control-sm"
                    v-model="form.fecha"
                    :class="$v.form.fecha.$invalid ? 'is-invalid' : 'is-valid'"
                  />
                </div>
                <div class="form-group col-md-3">
                  <label>Cantidad Producto (NSV)</label>
                  <input
                    type="number"
                    class="form-control form-control-sm"
                    v-model="form.cant_prod"
                    :class="
                      $v.form.cant_prod.$invalid ? 'is-invalid' : 'is-valid'
                    "
                  />
                </div>
                <div class="form-group col-md-3">
                  <label>Cantidad Guias</label>
                  <input
                    type="number"
                    class="form-control form-control-sm"
                    v-model="form.cant_guias"
                    :class="
                      $v.form.cant_guias.$invalid ? 'is-invalid' : 'is-valid'
                    "
                  />
                </div>
              </div>
            </div>
            <div class="modal-footer justify-content-between">
              <button
                type="button"
                class="btn btn-primary"
                v-show="!$v.form.$invalid"
                @click="save()"
              >
                Guardar
              </button>
            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>

      <!-- Modal de agregar y editar -->
      <div class="modal fade" id="modal-diferencia-show">
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-header bg-frontera-top-left pt-2 pb-2">
              <h4 class="modal-title text-white">Detalle</h4>
              <button
                type="button"
                class="close text-white"
                data-dismiss="modal"
                aria-label="Close"
                id="close-modal"
                ref="closeModal"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <!-- Cuerpo del modal -->
            <div class="modal-body table-responsive">
              <table
                class="
                  table table-bordered table-striped table-hover
                  text-nowrap
                  table-sm
                "
              >
                <thead>
                  <tr>
                    <th>N°</th>
                    <th>Guia</th>
                    <th>Placa</th>
                    <th>Cedula conductor</th>
                    <th>Origen</th>
                    <th>Producto</th>
                    <th>Destino</th>
                    <th>Ventana Descargue</th>
                    <th>Fecha de cargue</th>
                    <th>Fecha de descargue</th>
                    <th>BBL NSV Cargue</th>
                    <th>BBL NSV Descargue</th>
                    <th>Faltante</th>
                    <th>Estado</th>
                    <th>Fecha correo</th>
                    <th>Lista correos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="data in modalDiferencia" :key="data.id">
                    <td>{{ data.id }}</td>
                    <td>
                      {{ data.viaje.guia.numero }}-{{
                        data.viaje.guia.digito_verificacion
                      }}
                    </td>
                    <td>{{ data.viaje.guia.det_guias[0].vehiculo.placa }}</td>
                    <td>
                      {{
                        data.viaje.guia.det_guias[0].conductor.numero_documento
                      }}
                    </td>
                    <td>
                      {{ data.viaje.guia.det_guias[0].sitio_origen.nombre }}
                    </td>
                    <td>
                      {{ data.viaje.guia.det_guias[0].producto_liquido.nombre }}
                    </td>

                    <td>
                      {{ data.total_cierre_campo.ventana_enturne.nombre }}
                    </td>
                    <td>
                      {{ data.viaje.guia.det_guias[0].sitio_destino.nombre }}
                    </td>
                    <td>
                      {{ data.viaje.guia.det_guias[0].fecha_expedicion }}
                    </td>
                    <td>
                      {{ data.viaje.trazas_fechas_viaje.fecha_descargue }}
                    </td>
                    <td>{{ data.nsv_cargue }}</td>
                    <td>{{ data.nsv_descargue }}</td>
                    <td>{{ data.nsv_diferencia }}</td>
                    <td>
                      <div v-if="data.estado == 2">
                        <h5>
                          <span class="badge badge-success"
                            >Correo enviado</span
                          >
                        </h5>
                      </div>
                      <div v-else-if="data.estado == 1">
                        <h5>
                          <span class="badge badge-warning"
                            >Correo sin enviar</span
                          >
                        </h5>
                      </div>
                    </td>
                    <td>{{ data.fecha_envio }}</td>
                    <td>{{ data.lista_correos }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>

      <!-- Modal Export -->
      <div
        class="modal fade"
        id="modal-form-totalCierre-export"
        style="background-color: #00000082"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div class="modal-dialog modal-md">
          <div class="modal-content">
            <div class="modal-header bg-frontera-top-left pt-2 pb-2">
              <h4 class="modal-title text-white">
                Generar Listado Perdida producto
              </h4>
              <button
                type="button"
                class="close text-white"
                data-dismiss="modal"
                aria-label="Close"
                id="close-modal"
                ref="closeModal1"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="form-group row">
                <label for="fecha_ini" class="col-md-5">Fecha Inicial</label>
                <input
                  type="date"
                  id="fecha_ini"
                  v-model="filtrosExcel.fecha_ini"
                  class="form-control form-control-sm col-md-7"
                  @input="validaFechas"
                  :class="
                    $v.filtrosExcel.fecha_ini.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                />
              </div>
              <div class="form-group row">
                <label for="fecha_fin" class="col-md-5">Fecha Fin</label>
                <input
                  type="date"
                  id="fecha_fin"
                  v-model="filtrosExcel.fecha_fin"
                  class="form-control form-control-sm col-md-7"
                  @input="validaFechas"
                  :class="
                    $v.filtrosExcel.fecha_fin.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                />
              </div>
            </div>
            <div class="modal-footer justify-content-between">
              <div>
                <button
                  type="button"
                  class="btn btn-sm bg-navy"
                  @click="generarListadoExcel()"
                  v-show="!$v.filtrosExcel.$invalid"
                >
                  Generar Listado
                  <i class="fas fa-file-download"> </i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "../../../../components/Loading";
import axios from "axios";
import pagination from "laravel-vue-pagination";
import vSelect from "vue-select";
import { required } from "vuelidate/lib/validators";

export default {
  name: "TotalCierreCampoIndex",
  components: {
    Loading,
    pagination,
    vSelect,
  },
  data() {
    return {
      page: 1,
      cargando: false,
      sitio: {},
      productoLiquido: {},
      cierresCampos: {},
      modalDiferencia: {},
      ventana_descargue: {},
      modal: {
        title: "",
        accion: "",
      },
      filtros: {
        sitio_id: null,
        producto_liquido_id: null,
        fecha: null,
        cant_prod: null,
        cant_guias: null,
      },

      filtrosExcel: {
        fecha_ini: null,
        fecha_fin: null,
      },
      listasForms: {
        sitios: [],
        ventanas: [],
        productosLiquidos: [],
        listaEstado: [
          { numeracion: 1, descripcion: "Pendiente" },
          { numeracion: 2, descripcion: "Completo" },
        ],
      },
      form: {
        id: null,
        accion: null,
        sitio_id: null,
        producto_liquido_id: null,
        ventana_enturne_descargue_id: null,
        fecha: null,
        cant_prod: null,
        cant_guias: null,
      },
    };
  },
  validations: {
    form: {
      sitio_id: {
        required,
      },
      producto_liquido_id: {
        required,
      },
      fecha: {
        required,
      },
      cant_prod: {
        required,
      },
      cant_guias: {
        required,
      },
      ventana_enturne_descargue_id: {
        required,
      },
    },
    filtrosExcel: {
      fecha_ini: { required },
      fecha_fin: { required },
    },
  },
  methods: {
    // Métodos iniciales
    async getIndex(page = 1) {
      this.page = page;
      //this.cargando = true;
      await axios
        .get("/api/hidrocarburos/totalesCierresCampo?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.cargando = false;
          this.cierresCampos = response.data;
        });
    },

    getSitios() {
      axios.get("/api/admin/sitios/lista").then((response) => {
        this.listasForms.sitios = response.data;
      });
    },

    async getVentanas() {
      await axios
        .get("/api/hidrocarburos/ventanasEnturnamiento/lista", {
          params: {
            sitio: this.sitio.id,
          },
        })
        .then((response) => {
          this.listasForms.ventanas = response.data;
        });
    },

    async getSelectSitios() {
      if (this.sitio != null) {
        this.form.sitio_id = this.sitio.id;
        await this.getVentanas();
      } else {
        this.form.sitio_id = null;
        this.listasForms.ventanas = [];
      }
    },

    getSelectVentana() {
      if (this.ventana_descargue != null) {
        this.form.ventana_enturne_descargue_id = this.ventana_descargue.id;
      } else {
        this.form.ventana_enturne_descargue_id = null;
      }
    },

    getProductosLiquidos() {
      axios.get("/api/hidrocarburos/Productos/lista").then((response) => {
        this.listasForms.productosLiquidos = response.data.map((producto) => {
          return {
            ...producto,
            nombre: `${producto.nombre} (${
              producto.sitio ? producto.sitio.nombre : "Sin Sitio"
            })`,
          };
        });
      });
    },
    getSelectProductos() {
      if (this.productoLiquido != null) {
        this.form.producto_liquido_id = this.productoLiquido.id;
      } else {
        this.form.producto_liquido_id = null;
      }
    },

    // metodo de apertura del modal
    async abrirModal(accion, idCierre) {
      this.cargando = true;
      this.modal.accion = accion;
      this.modal.title = accion + " Cierre de Campo";
      await this.getSitios();
      await this.getProductosLiquidos();
      if (accion == "Editar") {
        await this.show(idCierre);
        await this.getSelectSitios();
        this.form.accion = accion;
        this.cargando = false;
      } else {
        this.limpiarModal();
        this.form.accion = accion;
        this.sitio = {};
        this.productoLiquido = {};
        this.ventana_descargue = {};
        this.cargando = false;
      }
      this.cargando = false;
    },

    async abrirModalDiferencia(idCierre) {
      await axios
        .get("/api/hidrocarburos/totalesCierresCampo/showDiferencia", {
          params: { id: idCierre },
        })
        .then((response) => {
          this.modalDiferencia = response.data;
        });
    },

    // Métodos para validación de los formularios
    resetForm() {
      this.form = {
        sitio_id: null,
        producto_liquido_id: null,
        fecha: null,
        cant_prod: null,
        cant_guias: null,
      };
      this.sitio = {};
      this.productoLiquido = {};
    },

    // Métodos de acción de los formularios
    save() {
      if (!this.$v.form.$invalid) {
        this.cargando = true;
        axios
          .post("/api/hidrocarburos/totalesCierresCampo", this.form)
          .then((response) => {
            this.cargando = false;
            this.$refs.closeModal2.click();
            this.resetForm();
            this.getIndex(); //Lista de nuevo
            let error = response.data.msg;
            let msg = "";
            let icon = "";
            if (error) {
              msg = error;
              icon = "error";
            } else {
              msg = "Se guardo exitosamente...";
              icon = "success";
            }

            this.$swal({
              icon: icon,
              title: msg,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          })
          .catch((e) => {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title: "Ocurrió un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    async show(idCierre) {
      await axios
        .get("/api/hidrocarburos/totalesCierresCampo/show/" + idCierre)
        .then((response) => {
          this.cargando = false;
          this.form = response.data;
          this.sitio = {
            id: this.form.sitio_id,
            nombre: this.form.sitio.nombre,
          };
          this.form.sitio_id = this.sitio.id;

          this.productoLiquido = {
            id: this.form.producto_liquido_id,
            nombre: this.form.producto_liquido.nombre,
          };
          this.form.producto_liquido_id = this.productoLiquido.id;

          this.ventana_descargue = {
            id: this.form.ventana_enturne_descargue_id,
            nombre: this.form.ventana_enturne.nombre,
          };
          this.form.ventana_enturne_descargue_id = this.ventana_descargue.id;
        });
    },

    validaFechas() {
      if (this.filtrosExcel.fecha_ini && this.filtrosExcel.fecha_fin) {
        const fecha_menor = new Date(this.filtrosExcel.fecha_ini);
        const fecha_mayor = new Date(this.filtrosExcel.fecha_fin);
        // Se valida que la fecha inicial sea menor que la fecha final
        if (fecha_menor > fecha_mayor) {
          this.filtrosExcel.fecha_fin = null;
          this.$swal({
            icon: "error",
            title: `La fecha inicial no puede ser mayor a la fecha final...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },

    generarListadoExcel() {
      this.cargando = true;
      axios({
        method: "POST",
        url: "/api/hidrocarburos/totalesCierresCampo/export",
        data: { filtros: this.filtrosExcel },
      })
        .then((response) => {
          let data = response.data;
          this.cargando = false;
          location.href = data.url;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },

    destroy(id) {
      this.$swal({
        title: "Está seguro de eliminar este Cierre de Campo?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "No, Cancelar",
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          this.cargando = true;
          axios
            .delete("/api/hidrocarburos/totalesCierresCampo/" + id)
            .then(() => {
              this.cargando = false;
              this.getIndex();
              this.$swal({
                icon: "success",
                title: "Se eliminó el Tipo de Bono exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 4000,
                timerProgressBar: true,
              });
            });
        }
      });
    },

    limpiarModal() {
      this.form = {
        sitio_id: null,
        producto_liquido_id: null,
        ventana_enturne_descargue_id: null,
        fecha: null,
        cant_prod: null,
        cant_guias: null,
      };
    },
  },

  async mounted() {
    await this.getIndex();
    await this.getProductosLiquidos();
    await this.getSitios();
  },
};
</script>
